@import './variables.scss';

html,
body {
    padding: 0;
    margin: 0;
    height: 100%;
    font-family: $body-font;
    background-color: $background-colour;
    color: $body-text-colour;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font;
    margin-top: 10px;
    margin-bottom: 10px;
}

a {
    color: inherit;
    text-decoration: none;
}

p {
    a {
        color: $brand-primary-colour;
        text-decoration: underline;
    }
}

ul,
ol {
    padding-inline-start: 30px;
}

ul {
    list-style: circle;
}

* {
    box-sizing: border-box;
}
