@import 'mixins/media';

// Colours
$brand-primary-colour: #fb2f58;
$brand-primary-light-colour: #ff6c84;
$brand-primary-dark-colour: #c1002f;
$brand-secondary-colour: #2ffbd2;
$brand-secondary-light-colour: #7affff;
$brand-secondary-dark-colour: #00c7a1;
$background-colour: #fff;
$background-secondary-colour: #edf7f8;
$surface-colour: #fff;
$border-colour: #444;

// Text
$body-font: Verdana, Arial, Helvetica, sans-serif;
$header-font: 'Trebuchet MS', sans-serif;
$body-text-colour: #444;
$body-text-light-colour: #999;

// Forms
$input-default-colour: #c8c8c8;
$input-hover-colour: #808080;
$input-focus-colour: #eabb35;
$input-error-colour: #ff261e;
$input-disabled-colour: #aeaeae;
$placeholder-colour: #a2a2a2;

// Structure
$header-height-mobile: 70px;
$footer-height-mobile: 267px;
$header-height: 86px;
$footer-height: 241px;
